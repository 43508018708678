import React from "react";
import Banner from "../Home/Banner";
import CertificationSamples from "../../components/CertificationSamples";
import TextRow from "../Pillar/TextRow";
import CeoMessage from "./CeoMessage";
import CtaWithForm from "../Home/CtaWithForm";
import Footer from "../Home/Footer";
import GlobalCta from "../../components/GlobalCta";
import {
  legalTranscriptionHeaderdata,
  aboutUsBannerData,
  bannerSealData,
  contactusPopupData,
  thankYouPopUpData,
  aboutUscertificationSampleData,
  aboutUsTextRowData,
  ceoMessageData,
  aboutUscertificationSampleDataTwo,
  aboutctaWithFormData2,
  legalTranscriptionFooterData,
  aboutUsCtaData,
} from "../utils/sample-data";

function AboutUs() {
  return (
    <>
      <Banner
        data={aboutUsBannerData}
        headerData={legalTranscriptionHeaderdata}
        sealData={bannerSealData}
        contactusPopUpData={contactusPopupData}
        thankYouPopUpData={thankYouPopUpData}
        imgMaxWidth="max-w-xs"
      />
      <CertificationSamples
        data={aboutUscertificationSampleData}
        titleClassName="font-bold text-3xl text-blue font-primary xs:text-center sm:text-left"
      />
      <TextRow
        data={aboutUsTextRowData}
        background="bg-prussian-blue"
        maxWidth="max-w-6xl"
        className="grid 0px:grid-cols-1 lg:grid-cols-2"
        titleClassName="text-5xl text-white font-primary font-bold"
        descriptionClassName="text-lg leading-8 text-white font-opensans"
      />
      <GlobalCta
        data={aboutUsCtaData}
        line
        background="bg-gradient-to-r from-[#EB478C] to-[#EC9D5B] w-full mx-auto"
        className="flex xs:flex-col lg:flex-row items-center justify-between xs:gap-7 lg:gap-28 max-w-6xl mx-auto px-5 2xl:px-0 py-16"
      />
      <CeoMessage data={ceoMessageData} />
      <CertificationSamples
        data={aboutUscertificationSampleDataTwo}
        background="bg-circleGradient bg-cover"
        titleClassName="font-bold text-3xl text-blue font-primary xs:text-center sm:text-left"
      />
      <CtaWithForm
        data={aboutctaWithFormData2}
        background="bg-prussian-blue"
        btnStyle="bg-yellow hover:bg-lightYellow"
      />
      <Footer data={legalTranscriptionFooterData} />
    </>
  );
}
export default AboutUs;
