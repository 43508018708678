import React from "react";
import OptimizedImage from "../hooks/OptimizedImage";

export default function Index(title, icon, className) {
  if (icon === "amazon") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/amazon.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "google") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/google.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "microsoft") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/microsoft.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "youtube") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/youtube.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "bloomberg") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/bloomberg.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "spotify") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/spotify.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "wix") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/wix.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "hsbc") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/hsbc.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "canon") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/canon.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "mgm") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/mgm.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "code") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/code.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "sap") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/clients/sap.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso9001:2015") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/iso.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "bbc") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/bbc_logo.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "ashrena") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/1.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "ata") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/ata.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "clutch") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/clutch.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "uscis") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/uscis.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso9001:2015gold") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/9001_big.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso17100:2015gold") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/17100_big.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso18587:2017gold") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/quality-policy/accreditation/18587_big.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "iso9001:2015certificate") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/iso_9001.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "bbc-white") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/images/bbc_logo.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "amazon-white") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/frontend/images/location-template/amazon_1.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "microsoft-white") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/frontend/images/location-template/microsoft_1.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "youtube-white") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/frontend/images/location-template/youtube.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "bloomberg-white") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/frontend/images/location-template/bloomberg.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "wix-white") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/frontend/images/location-template/wix_1.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "hsbc-white") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/frontend/images/location-template/hsbc.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "hp") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/frontend/images/location-template/hp_1.svg"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "canon-white") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/icons/canon.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "yahoofin") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/yahoofin.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "reuters") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/reuters.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "cbs") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/cbs.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "daily") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/daily.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "startrib") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/startrib.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "entre") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/entre.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "harvard") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/harvard.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "towards") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/towards.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "lulu") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/featured-in/lulu.png"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "berlin") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/germany/berlin.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "dresde") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/germany/dresde.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "dusseldorf") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/germany/dusseldorf.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "munich") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/germany/munich.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "stuttgart") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/germany/stuttgart.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "barcelona") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/spain/barcelona.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "madrid") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/spain/madrid.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "malaga") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/spain/malaga.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "valencia") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/spain/valencia.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "katowice") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/poland/katowice.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "krakow") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/poland/krakow.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "poznan") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/poland/poznan.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "warsaw") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/poland/warsaw.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "wroclaw") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/poland/wroclaw.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "atlanta") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/atlanta.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "boston") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/boston.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "chicago") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/chicago.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "dallas") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/dallas.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "denver") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/denver.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "houston") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/houston.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "miami") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/miami.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "new-york") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/new-york.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "philadelphia") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/philadelphia.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "san-diego") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/san-diego.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "san-francisco") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/san-francisco.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "seattle") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/usa/seattle.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "ottawa") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/canada/ottawa.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "toronto") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/canada/toronto.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "telaviv") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/israel/telaviv.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "melbourne") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/australia/melbourne.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  } else if (icon === "sydney") {
    return (
      <OptimizedImage
        src="https://tomedes.gumlet.io/assets/agences-locations/australia/sydney.webp"
        alt={title}
        className={className ? className : "w-20 h-auto"}
      />
    );
  }
  return;
}
