import React from "react";
import Text from "../Text/index";
import Button from "../Button/index";
import textResolver from "../../core/utils/text-resolver";

function GlobalCta({ data, background, className, btnClasses }) {
  const { dataType = 0 } = data;
  return (
    <div
      className={`w-full ${
        background
          ? background
          : "bg-gradient-to-r from-[#EB478C] to-[#EC9D5B] max-w-6xl mx-auto"
      }`}
    >
      <div
        className={`${
          className
            ? className
            : "flex xs:flex-col lg:flex-row items-center gap-7 max-w-4xl mx-auto mb-16 px-5 lg:px-0 py-12"
        } `}
      >
        <Text
          as="p"
          className="text-[30px] leading-[46px] 0px:text-center md:text-left text-white font-primary font-medium max-w-2xl"
          id="link-orange"
        >
          {dataType === 1 ? data.heading : textResolver(data, "heading")}
        </Text>
        <Button
          title={dataType === 1 ? data.text : textResolver(data, "text")}
          href={dataType === 1 ? data.href : textResolver(data, "href")}
          className={`uppercase cursor-pointer flex items-center justify-center ${
            btnClasses
              ? btnClasses
              : " w-60 h-16 text-sm bg-blue text-white font-opensans font-semibold rounded-full hover:bg-prussian-blue-light transition-colors"
          }`}
        />
      </div>
    </div>
  );
}

export default GlobalCta;
