import React from "react";
import BoxContainer from "../BoxContainer/index";
import Text from "../Text/index";
import Button from "../Button/index";
import imageResolver from "../../core/image-resolver";
import textResolver from "../../core/utils/text-resolver";

function IsoCertified({
  data,
  background = "bg-certified bg-cover bg-top",
  className,
  dataType = 0,
}) {
  return (
    <BoxContainer
      background={background}
      className={`${className ? className : " pt-96 pb-5"}`}
    >
      <div className="max-w-5xl mx-auto px-5 xl:px-0">
        <Text
          as="h2"
          className="text-4xl text-blue font-primary font-bold text-center mb-6"
        >
          {dataType === 1 ? data.title : textResolver(data, "title")}
        </Text>
        <Text
          as="div"
          className="text-lg leading-8 text-blue font-opensans text-center"
        >
          {dataType === 1
            ? data.description
            : textResolver(data, "description")}
        </Text>
        {data.cta && (
          <Button
            title={
              dataType === 1 ? data.cta?.text : textResolver(data.cta, "text")
            }
            href={data.cta?.href}
            className="w-[218px] h-[53px] text-lg text-white text-center uppercase font-opensans font-medium bg-yellow rounded-full cursor-pointer flex items-center justify-center mx-auto mt-7 mb-12"
          />
        )}
        <div className="max-w-4xl mx-auto mt-12">
          <div className="flex xs:flex-col lg:flex-row gap-6 lg:gap-[118px]">
            {data.certificates.map(
              ({ title, icon, className, description }, i) => {
                return (
                  <div key={i} className="flex xs:flex-col items-center">
                    {imageResolver(
                      dataType === 1 ? title : textResolver({ title }, "title"),
                      icon,
                      className
                    )}
                    <p className="text-lg leading-[22px] text-bluegray font-opensans font-medium w-56 text-center">
                      {title}
                      <span className="block font-semibold text-center">
                        {dataType === 1
                          ? description
                          : textResolver({ description }, "description")}
                      </span>
                    </p>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </BoxContainer>
  );
}

export default IsoCertified;
