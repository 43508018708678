import * as React from "react";
import BoxContainer from "../BoxContainer";
import Text from "../Text";
import IsoCertified from "../IsoCertified";
import textResolver from "../../core/utils/text-resolver";

const CertificationSamples = ({
  data,
  background = "bg-white",
  titleClassName = "font-semibold text-3xl text-blue font-primary xs:text-center sm:text-left",
}) => {
  const { dataType = 0 } = data;
  return (
    <>
      <BoxContainer
        background={background}
        className="items-center py-8 px-5 2xl:px-0"
      >
        <div className="my-8 max-w-6xl mx-auto">
          <div className="md:grid grid-cols-2 items-center justify-items-center">
            <div className="hidden md:block md:py-12 pr-8">
              <img
                src={
                  dataType === 1 ? data.imgurl : textResolver(data, "imgurl")
                }
                alt="business"
                className="rounded-[4px]"
              />
            </div>
            <div className="flex flex-col lg:pl-5">
              <div className="pb-12">
                <Text
                  className={titleClassName}
                  as="h2"
                  highlightedText={
                    dataType === 1
                      ? data.highlightWords
                      : textResolver(data, "highlightWords")
                  }
                  highlightedColor={data.highlightedColor}
                >
                  {dataType === 1 ? data.title : textResolver(data, "title")}
                </Text>
                <Text
                  className="mb-8 md:mb-0 mt-8 text-blue leading-8 font-opensans text-lg xs:text-center sm:text-left"
                  as="div"
                >
                  {dataType === 1
                    ? data.description
                    : textResolver(data, "description")}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </BoxContainer>
      {data.isoCertifiedData && (
        <IsoCertified
          data={data.isoCertifiedData}
          dataType={data.dataType}
          background="bg-gradient-to-t from-gradient1 to-white"
          className="pt-10 pb-24 px-5 lg:px-0"
        />
      )}
    </>
  );
};
export default CertificationSamples;
