import React from "react";
import BoxContainer from "../../components/BoxContainer";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import CeoImg from "../../assets/ceo.jpg";

function CeoMessage({ data, background = "bg-white" }) {
  const { dataType = 0 } = data;
  return (
    <BoxContainer background={background} className="px-5 2xl:px-0 py-20">
      <div className="flex gap-8 lg:gap-14">
        <div className="flex 0px:flex-col md:flex-row 0px:gap-4 md:gap-9 items-start pt-10">
          <FontAwesomeIcon
            className="text-4xl text-yellow inline-block"
            icon={faQuoteLeft}
          />
          <div>
            <Text
              as="h3"
              className="text-basemd text-[#949494] font-primary uppercase pb-8"
            >
              {dataType === 1 ? data.subTitle : textResolver(data, "subTitle")}
            </Text>
            <Text
              as="h2"
              highlightedText={
                dataType === 1
                  ? data.highlightWords
                  : textResolver(data, "highlightWords")
              }
              highlightedColor={data.highlightedColor}
              className="text-4xl leading-[65px] text-blue font-primary font-bold pb-7"
            >
              {dataType === 1 ? data.title : textResolver(data, "title")}
            </Text>
            <Text
              as="p"
              className="text-lg leading-8 text-blue font-opensans pb-6"
            >
              {dataType === 1 ? data.message : textResolver(data, "message")}
            </Text>
            <Text
              as="h2"
              className="text-2xl text-blue font-primary font-medium"
            >
              {dataType === 1 ? data.name : textResolver(data, "name")}
            </Text>
          </div>
        </div>
        <img
          src={data.ceoImg}
          alt="ceo"
          className="hidden md:block w-40 lg:w-60 max-w-full h-40 lg:h-60"
        />
      </div>
    </BoxContainer>
  );
}
export default CeoMessage;
