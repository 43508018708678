import React from "react";
import AboutUs from "../LegalTranscription/AboutUs";
import Seo from "../components/seo";

const Index = () => {
  return (
    <>
      <Seo
        title="About Us - LexTranscribe"
        description="Ready to work with a reliable legal transcription agency? Learn about LexTranscribe and our accurate legal transcription. Increase your billable hours now."
        slug="/about-us"
      />
      <AboutUs />
    </>
  );
};
export default Index;
