import React from "react";
import BoxContainer from "../../components/BoxContainer";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";

function TextRow({
  data,
  background = "bg-white",
  maxWidth = "max-w-4xl",
  className = " ",
  titleClassName = "text-5xl text-blue text-center font-primary font-bold pb-12",
  descriptionClassName = "text-lg leading-8 text-blue text-center font-opensans",
  rectangleBar = false,
}) {
  const { dataType = 0 } = data;
  return (
    <BoxContainer background={background} className="px-5 2xl:px-0 py-24">
      <div className={`w-full ${maxWidth} mx-auto`} id="link-orange">
        <div className={className}>
          <Text
            as="h2"
            highlightedText={
              dataType === 1
                ? data.highlightWords
                : textResolver(data, "highlightWords")
            }
            highlightedColor={data.highlightedColor}
            className={`${titleClassName}`}
          >
            {dataType === 1 ? data.title : textResolver(data, "title")}
          </Text>
          {rectangleBar && (
            <div className="mb-12 pt-4 w-24 mx-auto bg-gradient-to-r from-[#EB478C] to-[#EC9D5B]"></div>
          )}
          <Text as="div" className={`${descriptionClassName}`}>
            {dataType === 1 ? data.subtitle : textResolver(data, "subtitle")}
          </Text>
        </div>
      </div>
    </BoxContainer>
  );
}
export default TextRow;
